// Scroll To Top JS Start
$('#scroll-to-top').on('click', function () {
    $("html, body").animate({
        scrollTop: 0
    }, 200);
    return false;
});
$(window).on('scroll', function () {
    if ($(window).scrollTop() > 300) {
        $("#scroll-to-top").fadeIn(500);
    } else {
        $("#scroll-to-top").fadeOut(500);
    }
});
// Scroll To Top JS End

// Sticky Header JS Start
$(window).on('scroll', function () {
    if ($(window).scrollTop() >= 100) {
        $('.site-header').addClass('sticky-header');
    } else {
        $('.site-header').removeClass('sticky-header');
    }
});
// Sticky Header JS End

function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
            $('#imagePreview').css('background-image', 'url('+e.target.result +')');
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
        }
        reader.readAsDataURL(input.files[0]);
    }
}
$("#imageUpload").change(function() {
    readURL(this);
});

function updateTimer() {
    future = Date.parse("sept 2, 2020 11:30:00");
    now = new Date();
    diff = future - now;

    days = Math.floor(diff / (1000 * 60 * 60 * 24));
    hours = Math.floor(diff / (1000 * 60 * 60));
    mins = Math.floor(diff / (1000 * 60));
    secs = Math.floor(diff / 1000);

    d = days;
    h = hours - days * 24;
    m = mins - hours * 60;
    s = secs - mins * 60;

    if (document.getElementById("timer")) {
        document.getElementById("timer").innerHTML = "<div>" + h + "<span>Hours</span></div>" + "<div>" + m + "<span>Minutes</span></div>" + "<div>" + s + "<span>Seconds</span></div>";
    }
}
setInterval('updateTimer()', 1000);

//generate captcha
function generateCaptcha(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
}

//default captcha
$('.dynamic-code').text(generateCaptcha(5, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'));

$('.captcha-reload').on('click', function () {
    $('.dynamic-code').text(generateCaptcha(5, '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'));
});

//check captcha
$('#captcha-input').on('change', function () {
    if($(this).val() != $('.dynamic-code').text()){
        $('#errCaptcha').html('<span style="color: red;"><i class="ion-close"></i> Captecha not matched.</span>');
        $(this).val('');
    }else {
        $('#errCaptcha').html('');
    }
});

$(function () {
    $("#toggle_pwd").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var type = $(this).hasClass("fa-eye-slash") ? "text" : "password";
      $("#password").attr("type", type);
    });
  });

  $(function () {
    $("#toggle_pwdregister").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var type = $(this).hasClass("fa-eye-slash") ? "text" : "password";
      $("#password").attr("type", type);
    });
  });
  
  $(function () {
    $("#toggle_pwdold" || "#toggle_pwdnew" || "#toggle_pwdconfirm").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var type = $(this).hasClass("fa-eye-slash") ? "text" : "password";
      $("#oldPassword" || "#newPassword" || "#newPassword2").attr("type", type);
    });
  });

  $('#my_button').click(function() {
    $('#booknowModal').modal('hide');
});
